<script lang="js">
const classEnum = { NEGATIVE: 1, NEUTRAL: 0, POSITIVE: 2 };
export default {
  name: 'DataQualityBarChart',
  props: {
    caption: {
      type: String,
      default: 'Data Quality',
      required: true,
    },
    chartData: {
      type: Array,
      validator: (obj) => {
        if (!obj) return true; // allow for non required prop
        if (!Array.isArray(obj)) return;

        const tests = obj.filter((item) => {
          void item;
          return (
            item &&
            item.unitCount &&
            Number.isInteger(item.unitCount) &&
            item.unitCount > 0 &&
            item.class &&
            item.class.toUpperCase() &&
            Object.keys(classEnum).includes(item.class.toUpperCase())
          );
        });

        return tests.length;
      },
      default() {
        return [
          { unitCount: 150, class: 'negative', types:37, title: 'Elements with Invalid Type Code.' },
          { unitCount: 250, class: 'neutral', title: 'Elements without a Type Code.'},
          { unitCount: 60, class: 'positive', types:7, title: 'Elements with Valid Type Codes' },
        ];
      },
    },
    sort: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {};
  },
  computed: {
    totalUnitCount() {
      return this.chartData.reduce((acc, curr) => acc + curr.unitCount, 0);
    },
    sortedItems() {
      const items = [...this.chartData];
      if (!this.sort) return items;

      return items.sort((a, b) => {
        const A = classEnum[a.class.toUpperCase()];
        const B = classEnum[b.class.toUpperCase()];
        return A === B ? 0 : A > B ? 1 : -1;
      });
    },
    items() {
      return this.sortedItems
        .map((item, i) => {
          const percentage = (item.unitCount / this.totalUnitCount) * 100;
          const start = this.subTotalUnitCount[i] - item.unitCount;
          const startPercentage = (start / this.totalUnitCount) * 100;
          return { ...item, percentage, start, startPercentage };
        })
    },
    subTotalUnitCount() {
      return this.sortedItems.reduce((acc, curr) => {
        return [...acc, (acc.slice(-1) || 0) * 1 + curr.unitCount];
       } , []);
    },
  },
  beforeCreate() {
    this.enum = classEnum;
  },
};
</script>

<template>
  <figure class="chart">
    <svg viewBox="0 0 10 100" preserveAspectRatio="xMaxYMax">
      <g
        v-for="(item, i) in items"
        :key="i"
        :transform="`translate(0,${item.startPercentage})`"
      >
        <line
          :y1="0"
          :y2="0"
          x1="0"
          x2="100%"
          stroke-width="0.125"
          stroke="#000"
        />
        <rect :class="item.class" :height="item.percentage" width="100%" />
        <text
          :y="item.percentage / 2"
          :transform="`rotate(-90, 5, ${item.percentage / 2}) translate(0,0.5)`"
          class="count"
          x="5"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          {{ item.unitCount }}
        </text>
      </g>
      <rect
        width="100%"
        height="100%"
        stroke-width="0.25"
        stroke="#000"
        fill="transparent"
      />
    </svg>
    <figcaption>{{ caption }}</figcaption>
  </figure>
</template>

<style lang="scss" scoped>
.chart {
  margin: 0;
  padding: 0;
  font-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

svg {
  height: 50vh;
}

figcaption {
  font-size: 0.8rem;
  padding: 0;
  padding-top: 8px;
  border-top: 1px solid var(--v-grfn-darken1);
  margin: 0;
  margin-top: 16px;
  text-align: center;
  color: var(--v-default-base);
  width: 100%;
}

.positive {
  fill: var(--v-positive-base);
}

.negative {
  fill: var(--v-negative-darken4);
}

.neutral {
  fill: var(--v-grfn-darken3);
}
.count {
  fill: white;
  font-size: 4px;
  font-weight: 600;
  text-anchor: middle;
}
</style>
