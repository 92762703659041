<script>
import DataQualityBarChart from '../components/DataQualityBarChart.vue';

export default {
  name: 'DataQuality',
  components: { DataQualityBarChart },
  props: {
    elements: {
      type: Number,
      required: false,
      default: 100,
    },
    seed: {
      type: Number,
      required: false,
      default: 100,
    },
    aptitudeAndWillingness: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    bias() {
      const rolls = [];
      for (
        let i = 0;
        i < Math.sqrt(25 - this.aptitudeAndWillingness + 1);
        i++
      ) {
        rolls.push(
          (Math.random(this.seed) *
            Math.log(25 - this.aptitudeAndWillingness + 2)) /
            3.3
        );
      }
      return Math.max(...rolls);
    },
    elementCount() {
      return Math.round(this.elements * this.bias * 2);
    },
    typeCodedElements() {
      return Math.round(this.bias * this.elementCount);
    },
    unCodedElements() {
      return this.elementCount - this.typeCodedElements;
    },
    validTypecodes() {
      return Math.round(this.bias * this.typeCodedElements);
    },
    invalidTypecodes() {
      return this.typeCodedElements - this.validTypecodes;
    },
    awSlider() {
      return 25 - this.aptitudeAndWillingness;
    },
  },
  mounted() {
    console.log('Data Quality mounted');
  },
};
</script>

<template>
  <div>
    <v-container class="charts">
      <v-row>
        <!-- <v-col>
          <data-quality-bar-chart
            caption="Element Count"
            :chart-data="[{ unitCount: elementCount, class: 'positive' }]"
          />
        </v-col> -->
        <v-col>
          <data-quality-bar-chart
            :chart-data="[
              { unitCount: unCodedElements, class: 'negative' },
              { unitCount: typeCodedElements, class: 'positive' },
            ]"
            :sort="false"
            caption="Elements with a Type Code"
          />
        </v-col>
        <v-col
          ><data-quality-bar-chart
            :chart-data="[
              { unitCount: unCodedElements, class: 'negative' },
              { unitCount: invalidTypecodes, class: 'negative' },
              { unitCount: validTypecodes, class: 'positive' },
            ]"
            :sort="false"
            caption="Elements with a Valid Type Code"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-btn icon @click="$emit('reSeed')"> <v-icon>mdi-cached</v-icon></v-btn>
    <div
      style="
        display: inline-block;
        width: 400px;
        padding: 0 0 0 40px;
        margin-top: 45px;
        position: relative;
        top: 16px;
      "
    >
      <v-slider
        :value="awSlider"
        :min="0"
        :max="25"
        color="action"
        thumb-color="grey"
        track-color="grey"
        thumb-label="always"
        inverse-label
        label="Aptitude &amp; Willingness"
        @end="$emit('aptitudeAndWillingness', 25 - $event)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  margin: 0;
  padding: 0;
  font-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

figcaption,
svg {
  height: 50vh;
  // width: 100px;
}
.charts {
  align-items: flex-start;
  padding-right: 15px;
  margin-top: 115px;
  margin-left: 0;
}

.row {
  column-gap: 1rem;
}

.col {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  width: 200px;
}

figcaption {
  font-size: 0.8rem;
  padding: 0;
  padding-top: 8px;
  border-top: 1px solid var(--v-grfn-darken1);
  margin: 0;
  margin-top: 16px;
  text-align: center;
  color: var(--v-default-base);
  width: 100%;
  max-height: 4rem;
}

.positive {
  fill: var(--v-positive-base);
}

.negative {
  fill: var(--v-negative-darken4);
}

svg .count {
  fill: white;
  font: bold 4px sans-serif;
  font-family: 'Jost';
  text-anchor: middle;
}
</style>
